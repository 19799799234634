import { Conf } from '@services/conf/types';

export const defaultConf: Conf = {
  environment: 'Local development',
  debug: false,
  languages: ['es', 'en'],
  api: {
    url: 'https://api-odata.test.majesticresorts.com/api-gateway',
    user: 'checkin-user',
    password: 'mVI5@-,J&99U',
  },
  email: {
    providers: {
      aws: {
        url: 'https://2mo24pnpl5.execute-api.us-east-1.amazonaws.com/test/send-mail',
      },
    },
  },
  monitor: {
    providers: {
      sentry: {
        dsn: 'https://02ee7081f13129e9d19aab362f944082@o1381156.ingest.us.sentry.io/4507302903283712',
      },
    },
  },
  defaults: {
    fillContraints: {
      AD: 'required',
      JR: 'optional',
      NI: 'optional',
      CU: 'none',
    },
    language: 'en',
  },
  hotels: [
    {
      id: 'M1',
      enabled: true,
      name: 'Majestic Colonial Punta Cana',
      password: 'M1',
    },
    {
      id: 'M2',
      enabled: true,
      name: 'Majestic Elegance Punta Cana',
      password: 'M2',
    },
    {
      id: 'M3',
      enabled: true,
      name: 'Majestic Mirage Punta Cana',
      password: 'M3',
    },
    {
      id: 'M4',
      enabled: true,
      name: 'Majestic Elegance Costa Mujeres',
      password: 'M4',
    },
  ],
};
